<template>
  <div>
    <!-- É renderizado o html abaixo para modificar as tabelas -->
    <div id="template-prod" v-show="false" v-html="template"></div>
    <div class="text-center" v-show="loading">
      <span>Não foi possível carregar o documento, tente novamente...</span>
    </div>
    <div :style="styleCss">
      <DocumentPublic ref="document" :template="documentPreview('prod')" :d="parameter" v-show="!loading" />
    </div>
  </div>
</template>
<script>

import DocumentPublic from "@nixweb/nixloc-ui/src/component/shared/DocumentPublic";

import axios from 'axios';

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "DocumentPublicView",
  components: {
    DocumentPublic,
  },
  data() {
    return {
      baseUrl: "https://espaco.blob.core.windows.net/nixloc-generate-pdf",
      nameFileTemplate: this.$route.params.nameFileTemplate,
      nameFileParameter: this.$route.params.nameFileParameter,
      template: "",
      parameter: {},
      loading: false,
      styleCss: "",
    };
  },
  mounted() {
    this.loadTemplate();
  },
  computed: {
    ...mapGetters("generic", ["documentPreview"]),
  },
  methods: {
    ...mapActions("generic", ["getDocumentApi", "getPdfApi", "postApi"]),
    ...mapMutations("generic", ["updateDocumentHtml"]),
    loadTemplate() {
      const url = `${this.baseUrl}/${this.nameFileTemplate}.txt`
      axios.get(url).then((response) => {
        this.template = response.data;
        this.loadParameter();
      })
    },
    loadParameter() {
      const url = `${this.baseUrl}/${this.nameFileParameter}.txt`
      axios.get(url).then((response) => {
        this.styleCss = response.data.styleCss;
        this.parameter = response.data;
        let self = this;
        setTimeout(function () {
          self.updateDocumentHtml(self.template);
          self.template = self.documentPreview;
          self.loading = false;
        }, 100);
      })
    },
  },
};
</script>
<style scoped>
.div-loading {
  margin-top: 30px;
}
</style>